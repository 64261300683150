'use strict';

;
(function ($) {

  $('.home-slider').slick({
    slide: '.home-slide',
    dots: true,
    fade: true
  });

  $('.js-open-popup').magnificPopup({
    type: 'inline'
  });

  $('#popup-login form').on('submit', function () {
    $(this).find('.field-wrap').addClass('field-wrap--required');
    $(this).find('.error-msg').velocity('fadeIn');
    return false;
  });

  $(document).on('click', '.js-tooltip-toggle', function () {
    var $item = $(this).closest('.slide-tooltip');

    $('.slide-tooltip').not($item).removeClass('open toLeft toTop');

    if ($(window).width() - $item.offset().left - 30 < 300) {
      $item.addClass('toLeft');
    }
    if ($(window).height() - $item.offset().top + 120 < 400) {
      $item.addClass('toTop');
    }

    $item.toggleClass('open');
    $(document).off('click.tooltip').on('click.tooltip', function (e) {
      if ($(e.target).closest('.slide-tooltip').length == 0 && !$(e.target).hasClass('.slide-tooltip__btn')) $('.slide-tooltip').removeClass('open');
    });
  }).on('click', '.js-toggle-menu', function () {
    $('html').toggleClass('opened-menu');
  }).on('click', '.js-open-faq', function () {
    var $this = $(this),
        $item = $this.closest('.faq__item');

    $('.faq__item').not($item).removeClass('active').find('.faq__content').velocity('slideUp', 200);
    if (!$item.hasClass('active')) $item.addClass('active').find('.faq__content').velocity('slideDown', 200);else $item.removeClass('active').find('.faq__content').velocity('slideUp', 200);
  });
})(jQuery);